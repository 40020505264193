<template>
  <div class="mx-auto px-4 lg:px-6 sm:px-6">
    <div class="max-w-7xl items-center py-20 mx-auto lg:flex">
      <div class="flex-1">
        <b class="mx-auto text-base text-braintrust-mint">{{$t('mmc.content1.subtitle')}}</b>
        <h1 class="py-3 text-4xl leading-8 font-extrabold text-gray-900 sm:text-5xl">Medical Management <br/>  Consulting Services</h1>
      </div>

      <div class="flex-1">
        <p>{{$t('mmc.content1.normal1')}} <b class="text-braintrust-mint">{{$t('mmc.content1.bold')}}</b> {{$t('mmc.content1.normal2')}}</p>
      </div>
    </div>
  </div>

  <div class="scroll-bg"></div>

  <div class="mx-auto px-4 lg:px-6 sm:px-6">
    <div class="max-w-7xl items-center py-20 mx-auto flex flex-col lg:flex-row">
      <div class="flex-1 order-2 lg:order-1 lg:mt-0 mt-20">
        <img src="@/assets/images/mmc/mmc.png" alt="">
      </div>

      <div class="flex-1 order-1 lg:order-2 lg:px-5">
        <b class="mx-auto text-base text-braintrust-mint">Our Services</b>
        <h2 class="py-3 text-3xl leading-8 font-extrabold text-gray-900 sm:text-4xl">{{$t('mmc.content2.subject')}}</h2>
        <div class="justify-between flex-wrap lg:flex mb-4">
          <div class="flex lg:w-1/2">
            <b class="numbers">01</b>
            <p>{{$t('mmc.content2.01')}}</p>
          </div>
          <div class="flex lg:w-1/2">
            <b class="numbers">02</b>
            <p>{{$t('mmc.content2.02')}}</p>
          </div>
          <div class="flex lg:w-1/2">
            <b class="numbers">03</b>
            <p>{{$t('mmc.content2.03')}}</p>
          </div>
          <div class="flex lg:w-1/2">
            <b class="numbers">04</b>
            <p>{{$t('mmc.content2.04')}}</p>
          </div>
          <div class="flex lg:w-1/2">
            <b class="numbers">05</b>
            <p>{{$t('mmc.content2.05')}}</p>
          </div>
        </div>
        <h3 class="font-bold text-lg py-2 w-fit border-b-4 border-y-neutral-900">{{$t('mmc.content2.underline')}}</h3>
      </div>
    </div>

  </div>
</template>

<script>
export default {
}
</script>

<style scoped>
.scroll-bg{
  width: 100%;
  height: 600px;
  background: url('@/assets/images/mmc/mmc-bg2.jpg') no-repeat;
  background-size: cover; 
  background-attachment: fixed;
}
.numbers {
  color: #1248A2;
  font-weight: bold;
  font-size: 20px;
  margin-right: 10px;
}

@media (max-width: 1340px) {
  .scroll-bg{
    height: 300px;
      background: url('@/assets/images/mmc/mmc-bg2.jpg') no-repeat center;
  }
}
</style>