<template>
  <div class="px-4 agent-bg pt-20">
    <div class="mx-auto max-w-7xl lg:flex items-center">
      <div class="lg:w-1/2">
        <b class="text-braintrust-mint block">Our Services</b>
        <b class="text-braintrust-mint block">{{$t('agent.content1.subtitle')}}</b>
        <h1 class="py-5 lg:text-6xl text-4xl font-bold tracking-tight">
          Medical<br/>Doctor <span class="lg:text-3xl align-middle">|</span> Clinic <span class="lg:text-3xl align-middle">|</span> Hospital Agent Service
        </h1>
        <p class="py-5 xl:pr-60">
          {{$t('agent.content1.content')}}
        </p>
        <h3 class="font-bold text-lg py-2 w-fit border-b-4 border-y-neutral-900">{{$t('agent.content1.underline')}}</h3>
      </div>
      <div class="lg:w-1/2 flex-col flex">
        <div class="flex w-full xl:justify-center py-5 items-center">
          <div class="number">01</div>
          <p class="ml-5 w-2/3 lg:w-1/2">{{$t('agent.content1.01')}}</p>
        </div>
        <div class="flex w-full xl:justify-center py-5 items-center">
          <div class="number">02</div>
          <p class="ml-5 w-2/3 lg:w-1/2">{{$t('agent.content1.02')}}</p>
        </div>
        <div class="flex w-full xl:justify-center py-5 items-center">
          <div class="number">03</div>
          <p class="ml-5 w-2/3 lg:w-1/2">{{$t('agent.content1.03')}}</p>
        </div>
        <div class="flex w-full xl:justify-center py-5 items-center">
          <div class="number">04</div>
          <p class="ml-5 w-2/3 lg:w-1/2">{{$t('agent.content1.04')}}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.agent-bg {
  background: url('@/assets/images/agent/agent-bg1.png') no-repeat center -150px / cover;
  min-height: 750px;
}
.number {
  width: 75px;
  height: 75px;
  background: rgb(17,41,156);
  background: linear-gradient(90deg, rgba(17,41,156,1) 0%, rgba(0,168,163,1) 100%);
  border-radius: 50%;
  text-align: center;
  line-height: 75px;
  color: #fff;
  font-size: 20px;
  font-weight: bold;
}
@media (max-width: 1700px) {
  .agent-bg {
    background-position: center 100px;
  }
}
@media (max-width: 1024px) {
  .agent-bg {
    background-position: -400px 100px;
  }
}
</style>