<!-- This example requires Tailwind CSS v2.0+ -->
<template>
  <div class="badge"></div>
  <Disclosure as="nav" class="bg-white shadow" v-slot="{ open }">
    <div class="mx-auto px-2 sm:px-6 lg:px-6">
      <div class="relative flex justify-between h-16">
        <div class="absolute inset-y-0 left-0 flex items-center sm:hidden">
          <!-- Mobile menu button -->
          <DisclosureButton class="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
            <span class="sr-only">Open main menu</span>
            <MenuIcon v-if="!open" class="block h-6 w-6" aria-hidden="true" />
            <XIcon v-else class="block h-6 w-6" aria-hidden="true" />
          </DisclosureButton>
        </div>
        <div class="flex-1 flex items-center justify-center sm:items-stretch sm:justify-start">
          <div class="flex-shrink-0 flex items-center">
            <router-link to="/"><img class="block lg:hidden h-8 w-auto" src="@/assets/images/logo.png" alt="Brain Trust" /></router-link>
            <router-link to="/"><img class="hidden lg:block h-8 w-auto" src="@/assets/images/logo.png" alt="Brain Trust" /></router-link>
          </div>
          <div class="hidden sm:ml-6 sm:flex sm:space-x-8"> 
            <!-- Current: "border-indigo-500 text-gray-900 inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium", Default: "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700" -->
            <router-link :to="nav.href" v-for="nav in navs" :key="nav.name" :href="nav.href" :class="[isMach(nav.path) ? 'border-braintrust-mint-900 text-braintrust-mint inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium' : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700 inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium', errorClass]" class="">{{ nav.name }}</router-link>
          </div>
        </div>
        <div class="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0 cursor-pointer" @click="changeLanguage()">
          <button type="button" class="bg-white p-1 rounded-full text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
            <span class="sr-only">Language</span>
            <GlobeAltIcon class="h-6 w-6" aria-hidden="true"/>
          </button>
        </div>
      </div>
    </div>

    <DisclosurePanel class="sm:hidden">
      <div class="pt-2 pb-4 space-y-1">
        <!-- Current: "bg-indigo-50 border-indigo-500 text-indigo-700", Default: "border-transparent text-gray-500 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-700" -->
        <DisclosureButton as="span" @click="goPage(nav.href)" v-for="nav in navs" :key="nav.name" :class="[isMach(nav.path) ? 'bg-braintrust-mint-50 border-braintrust-mint-900 text-braintrust-mint block pl-3 pr-4 py-2 border-l-4 text-base font-medium' : 'border-transparent text-gray-500 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-700 block pl-3 pr-4 py-2 border-l-4 text-base font-medium']">{{ nav.name }}</DisclosureButton>
      </div>
    </DisclosurePanel>
  </Disclosure>
</template>

<script>
import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/vue'
import { GlobeAltIcon, MenuIcon, XIcon } from '@heroicons/vue/outline'

export default {
  components: {
    Disclosure,
    DisclosureButton,
    DisclosurePanel,
    GlobeAltIcon,
    MenuIcon,
    XIcon,
  },
  data(){
    return {
      navs: [
        {
          name: 'MMC Services',
          href: '/mmc',
          path: /^\/mmc/,
        },
        {
          name: 'Agent Service',
          href: '/agent',
          path: /^\/agent/,
        },
        {
          name: 'Support Services',
          href: '/support',
          path: /^\/support/,
        }
      ]
    }
  },
  methods: {
    isMach(path){
      if(!path) return false
      return path.test(this.$route.fullPath)
    },
    changeLanguage(){
      this.$i18n.locale = this.$i18n.locale === 'en' ? 'ko' : 'en'
    },
    goPage(url) {
      this.$router.push({path:url});
    }
  }
}
</script>

<style scoped>
  .badge {
    z-index: 1000;
    position: absolute;
    top: 3%;
    right: 5%;
    background-image: url('@/assets/images/badge.png');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    width: 80px;
    height: 80px;
  }

@media (max-width: 640px) {
  .badge {
    width: 65px;
    height: 65px;
    right: 13%;
  }
}
</style>