export default {
  "footer": {
    "company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["㈜브레인트러스트"])},
    "business-number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["사업자등록번호 220-88-40846"])},
    "ceo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["대표자 장석환"])},
    "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["서울 특별시 강남구 광평로 295, 서관 1020호(수서동, 사이룩스오피스텔)"])}
  },
  "mmc": {
    "content1": {
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["의료경영 컨설팅 서비스"])},
      "normal1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["다국적기업 및 대기업, 의료그룹에서의 근무 경험 및 노하우로,"])},
      "bold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ISO 9001:2015 의료전문경영 및 인적자원관리 컨설팅 인증"])},
      "normal2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["기업으로서, 2012년부터 사회적 공인이고 전문가인 닥터 고객들을 중심으로, 닥터 근무자 개인의 안정된 생활 및 병.의원이 의료 환경에 대처할 수 있는 서비스를 제공하고 있습니다."])}
    },
    "content2": {
      "subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["의료 변화에 따른 차별화된 지원 서비스 제공"])},
      "01": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["경영 개선 및 안정과 성장"])},
      "02": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["인사, 노무 환경 안정"])},
      "03": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["윈&윈 파트너쉽 지원 서비스"])},
      "04": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["최적 근무지(전국지역) 지원"])},
      "05": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["특화되고 경쟁력 있는 서비스 제공 (해외 근무지 추천, 신 의료사업 기획/개발 등)"])},
      "underline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["닥터 고객의 개별 보안 유지 원칙 중심 "])}
    }
  },
  "agent": {
    "content1": {
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1:1 전담 에이전트 서비스"])},
      "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["문화, 예술 분야의 프로들을 대상으로한 전담 에이전트사와 같이, 수시로 변하는 의료 환경 변화에 대처하기 위해 닥터 및 병원들을 위한 전담 에이전트 서비스를 고객의 선택. 요구 사항에 따라 브레인트러스트에서 제공하고 있습니다."])},
      "underline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["급여 보장, 매출.수익 안정 시스템 유지"])},
      "01": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["프리랜서 병.의원 근무지(전국 근무 희망 지역) 추천 및 지원"])},
      "02": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["장기. 단기 병.의원 근무지(전국 근무 희망 지역) 추천 및 지원"])},
      "03": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["로컬 병.의원 경영 관리 지원 서비스"])},
      "04": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["네트워크/그룹 병.의원 경영 관리 지원 서비스"])}
    }
  },
  "support": {
    "content1": {
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["고급 인력 채용 지원 서비스"])},
      "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["로칼 병.의원, 네트워크/그룹 병.의원 등에서는 안정된 인력 관리를 통해 정상적인 병원 운영이 요구되는 현상황에서, 브레인트러스트에서 보유하고 있는 인력 자원들을 통해 전국 단위로 필요한 고급 인력 채용 서비스를 제공 중입니다."])},
      "underline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["우수한 인력 선발 및 채용 원칙"])},
      "01": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["단기, 장기, 프리랜서 등 채용지원"])},
      "02": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["실장, 간부급 채용 지원"])}
    }
  }
}