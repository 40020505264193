import { createRouter, createWebHistory } from 'vue-router'
import Home from '@/views/HomeView.vue'
import Mmc from '@/views/MmcView.vue'
import Agent from '@/views/AgentView.vue'
import Support from '@/views/SupportView.vue'


const routes = [
  {
    path: '/',
    name: 'home',
    component: Home
  },
  {
    path: '/mmc',
    name: 'mmc',
    component: Mmc
  },
  {
    path: '/agent',
    name: 'agent',
    component: Agent
  },
  {
    path: '/support',
    name: 'support',
    component: Support
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router