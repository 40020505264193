<template>
  <div class="mx-auto">
    <div class="subject">
      <div class="h-full flex-col flex item-center justify-center text-center">
        <h1 class="lg:text-7xl text-4xl tracking-tight font-bold py-2">Key Manpower Support Service</h1>
        <b class="text-braintrust-mint block">{{$t('support.content1.subtitle')}}</b>
      </div>
    </div>
    <div class="px-4 sm:px-6 mx-auto max-w-7xl">
      <div class="max-w-7xl items-center py-10 lg:py-20 mx-auto lg:flex">
        <div class="flex-1">
          <b class="mx-auto text-base text-braintrust-mint">Our Services</b>
          <p class="py-3 text-gray-900 lg:mr-32">
            {{$t('support.content1.content')}}
          </p>
          <div class="justify-between flex flex-col lg: mb-4">
            <div class="flex items-center py-3">
              <div class="tag">Doctor</div>
              <b>{{$t('support.content1.01')}}</b>
            </div>
            <div class="flex items-center py-3">
              <div class="tag">Manager</div>
              <b>{{$t('support.content1.02')}}</b>
            </div>
          </div>
          <h3 class="font-bold text-lg py-2 w-fit border-b-4 mb-20 border-y-neutral-900 lg:mb-0">{{$t('support.content1.underline')}}</h3>
        </div>
        <div class="flex-1">
          <img src="@/assets/images/support/support.png" alt="">
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.subject {
  width: 100%;
  height: 400px;
  background: url('@/assets/images/support/support-bg1.png') no-repeat center bottom / cover;
}
.tag{
  color: #fff;
  padding: 10px 15px;
  background-color: #1248A2;
  border-radius: 10px;
  font-weight: bold;
  font-size: 20px;
  margin-right: 10px;
}
</style>