export default {
  "footer": {
    "company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BrainTrust Co., Ltd"])},
    "business-number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Business Registration No. 220-88-40846"])},
    "ceo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CEO. Seok-Hwan CHANG"])},
    "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["#1020(Suseo-dong, Cyroux Officetel) 295, Gwangpyeong-ro, Gangnam-gu, Seoul, Republic of Korea(06349)"])}
  },
  "mmc": {
    "content1": {
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Medical Management Consulting Services"])},
      "normal1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["With experience and know-how in multinational company, large corporation, and medical group, as an"])},
      "bold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ISO 9001:2015 medical professional management and human resource management consulting certified firm"])},
      "normal2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["since 2012, focusing on medical doctor clients, We have developed a stable life for doctors and adapted the medical environment for clinics and hospitals. These services include"])}
    },
    "content2": {
      "subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delivering differentiated support services as healthcare changes"])},
      "underline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Providing differentiated support services according to changes in medical environment"])},
      "01": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Management improvement and stability and growth"])},
      "02": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stability of personnel and labor environment"])},
      "03": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Win & Win Partnership Support Service"])},
      "04": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We are providing support services for optimal working locations (national)."])},
      "05": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Provide specialized and competitive services(Recommendation of overseas service area, planning/development of new medical businesses, etc.)"])}
    }
  },
  "agent": {
    "content1": {
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1:1 Dedicated Agent Service"])},
      "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brain Trust provides dedicated agent services for doctors and clinics, hospitals according to the client's choice and requirement in order to cope with the changing medical environment, such as a exclusive agent for professionals in the cultural and artistic fields. These services include"])},
      "underline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salary guarantee, sales and profit stabilization system maintenance"])},
      "01": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Local clinics working condition recommendation and support for freelances (where you want to work nationwide)"])},
      "02": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Short & Long-term working condition recommendation and support for doctors and local clinics, hospitals(where you want to work nationwide)"])},
      "03": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Management support service for local clinics and hospitals"])},
      "04": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Management support service for network/group clinics and hospitals"])}
    }
  },
  "support": {
    "content1": {
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Key Manpower Support Service"])},
      "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Advanced manpower recruitment support service In a current situation where normal hospital operation is required through stable manpower management in local clinics and hospitals, network/group clinics and hospitals, etc., through the manpower resources possessed by Brain Trust, high-quality manpower recruitment service required nationwide is being offered. These services include"])},
      "underline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Principles for selecting and hiring excellent personnel(Doctor and Manager)"])},
      "01": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Short-term, long-term, freelance recruitment support"])},
      "02": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Support for hiring chief and supervisor"])}
    }
  }
}