<template>
  <footer aria-labelledby="footer-heading">
    
    <div class="bg-stone-50 mx-auto py-8 px-4 sm:px-6 lg:px-8">
      <div class="lg:flex lg:items-center lg:justify-between xl:mt-0">
        
        <div>
          <p class="text-sm font-semibold text-gray-900" style="word-break:break-all;">{{$t('footer.company')}} | {{$t('footer.business-number')}} | <b>{{$t('footer.ceo')}}</b></p>
          <p class="mt-2 text-base text-gray-600">{{$t('footer.address')}}</p>
          <p><b>Tel</b> +82-2-6259-0501 &nbsp; <b>Fax</b> +82-2-6259-0505 &nbsp; <b>Mobile</b> +82-10-3782-8451</p>
        </div>

        <div class="lg:flex lg:items-end lg:justify-end flex-col pt-5">
          <span class="mb-2">contact us</span>
          <ul class="flex contacts space-x-3">
            <li v-for="contact in navigation.contacts" :key="contact.name" class="hover:border-cyan-800 rounded-full border-2 w-14 h-14 flex justify-center items-center"><a class="py-4 px-4 w-5" :href="contact.href" :target="[contact.blank == true?'_blank':'']"></a></li>
          </ul>
        </div>

      </div>
    </div>

    <div class="border-t border-gray-200 bg-stone-50 mx-auto py-8 px-4 sm:px-6 lg:px-8 lg:flex lg:items-center lg:justify-between">
      <div>
        <h3 class="text-sm font-semibold text-gray-400 tracking-wider uppercase">카카오톡 채널 상담 안내</h3>
        <p class="mt-2 text-base text-gray-500">톡상담 버튼을 클릭하시면, 카카오톡 온라인 상담이 가능합니다.<br/>상담 가능 시간: 월~일 또는 매일 24시간</p>
      </div>
      <div class="mt-4 sm:flex sm:max-w-md lg:mt-0">
        <div
          id="kakao-talk-channel-chat-button"
          data-channel-public-id="_XYxiLb"
          data-title="consult"
          data-size="small"
          data-color="yellow"
          data-shape="pc"
          data-support-multiple-densities="true"
        ></div>
        <!-- <a href="http://pf.kakao.com/_XYxiLb/chat" target="_blank"><img src="@/assets/images/sentence_type.png" alt="카카오채널 채팅"></a> -->
      </div>
    </div>

    

    <div class="bg-braintrust-navy-900 mx-auto py-5 px-4 sm:px-6 lg:py-5 lg:px-8">      
      <div class="md:flex md:items-center md:justify-between">
        <div class="flex space-x-4 md:order-2">
          <a v-for="item in navigation.social" :key="item.name" :href="item.href" target="_blank" class="text-gray-400 hover:text-gray-300">
            <span class="sr-only">{{ item.name }}</span>
            <component :is="item.icon" class="h-6 w-6" aria-hidden="true" />
          </a>
        </div>
        <p class="mt-8 text-base text-gray-400 md:mt-0 md:order-1">Copyrights 2022 Braintrust Co., Ltd All rights reserved</p>
      </div>
    </div>

  </footer>
</template>

<script>
import { defineComponent, h } from 'vue'
import { PhoneIcon } from '@heroicons/vue/solid'

const navigation = {
  contacts: [
    { name: 'Phone', href: 'tel:+82-2-6259-0501', blank: false},
    { name: 'Mobile', href: 'tel:82-10=3782-8451', blank: false},
    { name: 'Email', href: 'mailto:shchang@braintrustkorea.com', blank: true},
  ],
  social: [
    {
      name: 'Skype',
      href: 'https://join.skype.com/invite/ue5C68NEtEqP',
      icon: defineComponent({
        render: () =>
          h('svg', { fill: 'currentColor', viewBox: '0 0 30 30' }, [
            h('path', {
              'fill-rule': 'evenodd',
              d: 'M26.884,16.579C26.953,16.061,27,15.536,27,15c0-6.627-5.373-12-12-12c-0.536,0-1.061,0.047-1.579,0.116 C12.279,2.413,10.939,2,9.5,2C5.358,2,2,5.358,2,9.5c0,1.439,0.413,2.779,1.116,3.921C3.047,13.939,3,14.464,3,15 c0,6.627,5.373,12,12,12c0.536,0,1.061-0.047,1.579-0.116C17.721,27.587,19.061,28,20.5,28c4.142,0,7.5-3.358,7.5-7.5 C28,19.061,27.587,17.721,26.884,16.579z M15.149,22c-3.896,0-6.133-2.042-6.133-4.046c0-1.022,1.07-1.349,1.498-1.349 c0.989,0,1.318,0.807,1.498,1.177c1.005,2.066,1.971,1.794,2.923,1.884c0.841,0.079,2.815-0.292,2.815-1.734 c0-0.138,0.217-1.076-1.487-1.541c-1.089-0.298-3.009-0.663-4.589-1.271c-1.486-0.572-2.358-1.75-2.358-3.268 C9.316,11.093,9.705,8,14.967,8c3.512,0,5.384,1.799,5.384,3.382c0,0.364-0.139,0.685-0.418,0.974 c-0.279,0.289-1.065,0.712-1.926,0.161c-0.217-0.139-0.396-0.428-0.61-0.803c-0.258-0.471-0.68-1.381-2.623-1.381 c-1.885,0-2.408,0.899-2.408,1.274c0,1.26,2.405,1.525,3.051,1.692c2.358,0.608,5.566,1.167,5.566,4.346 C20.983,21.295,17.185,22,15.149,22z',
              'clip-rule': 'evenodd',
            }),
          ]),
      }),
    },
    {
      name: 'Linkedin',
      href: 'https://linkedin.com/in/seok-hwan-michael-chang-613b2444',
      icon: defineComponent({
        render: () =>
          h('svg', { fill: 'currentColor', viewBox: '0 0 50 50' }, [
            h('path', {
              'fill-rule': 'evenodd',
              d: 'M41,4H9C6.24,4,4,6.24,4,9v32c0,2.76,2.24,5,5,5h32c2.76,0,5-2.24,5-5V9C46,6.24,43.76,4,41,4z M17,20v19h-6V20H17z M11,14.47c0-1.4,1.2-2.47,3-2.47s2.93,1.07,3,2.47c0,1.4-1.12,2.53-3,2.53C12.2,17,11,15.87,11,14.47z M39,39h-6c0,0,0-9.26,0-10 c0-2-1-4-3.5-4.04h-0.08C27,24.96,26,27.02,26,29c0,0.91,0,10,0,10h-6V20h6v2.56c0,0,1.93-2.56,5.81-2.56 c3.97,0,7.19,2.73,7.19,8.26V39z',
              'clip-rule': 'evenodd',
            }),
          ]),
      }),
    },
    {
      name: 'Facebook',
      href: 'https://www.facebook.com/profile.php?id=100001417366942',
      icon: defineComponent({
        render: () =>
          h('svg', { fill: 'currentColor', viewBox: '0 0 24 24' }, [
            h('path', {
              'fill-rule': 'evenodd',
              d: 'M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z',
              'clip-rule': 'evenodd',
            }),
          ]),
      }),
    },
    {
      name: 'Instagram',
      href: 'https://www.instagram.com/shchang65/',
      icon: defineComponent({
        render: () =>
          h('svg', { fill: 'currentColor', viewBox: '0 0 24 24' }, [
            h('path', {
              'fill-rule': 'evenodd',
              d: 'M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z',
              'clip-rule': 'evenodd',
            }),
          ]),
      }),
    },
    {
      name: 'Twitter',
      href: 'https://twitter.com/shchang65',
      icon: defineComponent({
        render: () =>
          h('svg', { fill: 'currentColor', viewBox: '0 0 24 24' }, [
            h('path', {
              'fill-rule': 'evenodd',
              d: 'M19,3H5C3.895,3,3,3.895,3,5v14c0,1.105,0.895,2,2,2h14c1.105,0,2-0.895,2-2V5C21,3.895,20.105,3,19,3z M17.05,9.514 c0,0.086,0,0.171,0,0.343c0,3.257-2.486,7.029-7.029,7.029c-1.371,0-2.657-0.429-3.771-1.114c0.171,0,0.429,0,0.6,0 c1.114,0,2.229-0.429,3.086-1.029c-1.114,0-1.971-0.771-2.314-1.714c0.171,0,0.343,0.086,0.429,0.086c0.257,0,0.429,0,0.686-0.086 c-1.114-0.257-1.971-1.2-1.971-2.4c0.343,0.171,0.686,0.257,1.114,0.343c-0.686-0.6-1.114-1.286-1.114-2.143 c0-0.429,0.086-0.857,0.343-1.2c1.2,1.457,3,2.486,5.057,2.571c0-0.171-0.086-0.343-0.086-0.6c0-1.371,1.114-2.486,2.486-2.486 c0.686,0,1.371,0.257,1.8,0.771c0.6-0.086,1.114-0.343,1.543-0.6c-0.171,0.6-0.6,1.029-1.114,1.371 c0.514-0.086,0.943-0.171,1.457-0.429C17.907,8.743,17.479,9.171,17.05,9.514z',
              'clip-rule': 'evenodd',
            }),
          ]),
      }),
    }
  ],
}

export default {
  components: {
    PhoneIcon
  },
  setup() {
    return {
      navigation,
    }
  },
}
</script>

<style scoped>
.contacts li a {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.contacts li:nth-child(1) a{
  background-image: url('@/assets/images/phone.svg');
}
.contacts li:nth-child(2) a{
  background-image: url('@/assets/images/mobile.svg');
}
.contacts li:nth-child(3) a{
  background-image: url('@/assets/images/email.svg');
}
</style>