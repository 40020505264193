<template>
  <ul class="main-visual">
    <li v-for="v in visual" :key="v.title" :class="[ v.full == true ? 'full cursor-pointer' : 'cursor-pointer']" @click="visualClick(v.href)">
      <div class="py-8 px-4 text-white sm:px-6 lg:px-8 flex flex-col justify-center">
        <h1 class="text-3xl sm:text-5xl" v-html="v.title"></h1>
        <span class="mt-5" v-if="this.$i18n.locale === 'ko'">{{v.subTitle}}</span>
        <button type="button" class="mt-5 inline-flex items-center justify-center w-20 px-2.5 py-2 text-xs font-medium shadow-sm text-white bg-braintrust-mint-900 hover:bg-cyan-900 focus:outline-none focus:ring-2 focus:ring-offset-2">more</button>
      </div>
    </li>
  </ul>
</template>

<script>
const visual = [
  {
    title: "Medical Management Consulting Services",
    subTitle: "의료경영 컨설팅 서비스",
    href: '/mmc',
    full: true
  },
  {
    title: "Medical <br/> Doctor <span class='text-lg align-middle'>|</span> Clinic <span class='text-lg align-middle'>|</span> Hospital Agent Service",
    subTitle: "1:1 전담 에이전트 서비스",
    href: '/agent',
    full: false
  },
  {
    title: "Key Manpower Support Service",
    subTitle: "인력 채용 지원 서비스",
    href: '/support',
    full: false
  }
]
export default {
  data() {
    return {
      visual
    }
  },
  methods: {
    visualClick(url) {
      this.$router.push({path:url});
    }
  }
}
</script>

<style scoped>
.main-visual {
  height: calc(100vh - 64px - 236px);
  min-height: calc(100vh - 64px - 236px);
}
ul {
  display: flex;
  flex-wrap: wrap;
}
ul > li:first-child {
  padding: 42px 0;
}
ul > li {
  width: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  transition: opacity .3s;
}
ul > li > div {
  height: 100%;
}
ul > li.full {
  width: 100%;
  background-image: url('@/assets/images/main/main01.png');
  background-position-y: bottom;
}
ul > li:nth-child(2) {
  background-image: url('@/assets/images/main/main02.png');
  background-position-y: bottom;
}
ul > li:nth-child(3) {
  background-image: url('@/assets/images/main/main03.png');
  background-position-y: bottom;
}
ul > li:hover{
  opacity: .8;
}

@media (max-width: 1024px) {
  .main-visual {
    height: auto;
    min-height: auto;
    }
}

@media (max-width: 640px) {
  .main-visual {
    height: auto;
  }
  ul {
    flex-direction: column;
  }
  ul > li {
    width: 100%;
    max-height: 300px;
    min-height: auto;
  }
  ul > li:first-child {
    padding: 0;
  }
}
</style>