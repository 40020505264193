<template>
  <Header/>
  <Contents/>
  <Footer/>
</template>

<script>
import Header from './components/MainHeader.vue'
import Contents from './components/MainContents.vue'
import Footer from './components/MainFooter.vue'

export default {
  name: 'App',
  components: {
    Header,
    Contents,
    Footer
  }
}
</script>

<style>
#app {
  font-family: sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
</style>
